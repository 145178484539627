<template>
  <div>
    <Card :title="'Nova Permissão'">
      <FormAddEdit
        :modelSelectedGroup="selectedGroup"
        :modelUnities="modelUnities"
        ref="addEditForm"
        class="my-2"
      />
    </Card>
    <Card class="mt-2" :title="'Habilitar permissões'">
      <PermissionMenu
        v-for="node in nodes"
        :key="node.id"
        :node="node"
        :model.sync="model"
      />
      <hr />
      <div class="mb-3 d-flex justify-content-end">
        <b-button
          v-if="editing"
          @click="update_permissions"
          variant="primary-custom"
          >Alterar</b-button
        >
        <b-button v-else @click="save_permissions" variant="primary-custom"
          >Salvar</b-button
        >
      </div>
    </Card>    
  </div>
</template>

<script>
export default {
  data() {
    return {
      editing: false,
      model: [],
      modelUnities: [],
      selectedGroup: "",
    };
  },
  components: {
    PermissionMenu: () => import("./components/PermissionMenus.vue"),
    FormAddEdit: () => import("./components/FormAddEdit.vue"),
  },
  computed: {
    nodes() {
      return this.$store.state.permissions.permissions;
    },
    unities() {
      return this.$store.state.permissions.unities;
    },
  },
  methods: {
    async save_permissions() {
      const data = [];
      const groupAndUnities = this.$refs.addEditForm.get_model();
      _.forEach(groupAndUnities.selectedUnities, (unity) => {
        _.forEach(this.model, (permissionId) => {
          data.push({
            unity_id: unity,
            group_id: groupAndUnities.selectedGroup,
            permission_id: permissionId,
            acive: "1",
          });
        });
      });
      const response = await this.$store.dispatch(
        "permissions/save_permissions",
        data
      );
      if (response) {
        this.$router.push("/permissoes");
      }
    },
    async update_permissions() {
      const data = [];
      const groupAndUnities = this.$refs.addEditForm.get_model();
      _.forEach(groupAndUnities.selectedUnities, (unity) => {
        _.forEach(this.model, (permissionId) => {
          data.push({
            unity_id: unity,
            group_id: groupAndUnities.selectedGroup,
            permission_id: permissionId,
            acive: "1",
          });
        });
      });

      console.log(data);
      const response = await this.$store.dispatch(
        "permissions/update_permissions",
        data
      );
      if (response) {
        this.$router.push("/permissoes");
      }
    },
  },
  async mounted() {
    const groupId = this.$route.params.groupId;
    if (groupId) {
      this.editing = true;
      const permissions = await this.$store.dispatch(
        "permissions/get_group_permissions_by_id",
        groupId
      );

      this.selectedGroup = groupId;

      _.forEach(permissions, (permission) => {
        if (!this.model.includes(permission.permission_id)) {
          this.model.push(permission.permission_id);
        }
        if (!this.modelUnities.includes(permission.unity_id)) {
          this.modelUnities.push(permission.unity_id);
        }
      });
    }
    this.$store.dispatch("permissions/permissions_add_edit_init");
  },
};
</script>

<style>
</style>